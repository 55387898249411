import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/scaling-during-covid19-pandemic",
  "date": "20th July 2022",
  "title": "Scaling during the COVID-19 pandemic",
  "summary": "When the COVID-19 pandemic peaked in Australia in 2021, it highlighted more than ever before just how vital a robust hosting platform really is.",
  "author": "Griffyn Heels",
  "tag": "Case Study",
  "tagColor": "green",
  "tags": [{
    "name": "year in review"
  }, {
    "name": "scaling"
  }, {
    "name": "pandemic"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Autoscaling websites through high-traffic events is second nature to Skpr. `}</p>
    <p>{`When the COVID-19 pandemic peaked in Australia in 2021, it highlighted more than ever before just how
vital a robust hosting platform really is. `}</p>
    <h2>{`An autoscaling case study`}</h2>
    <p>{`The virus created a fast-changing environment. Daily briefings from state and territory governments regularly
altered the landscape for people everywhere.`}</p>
    <p>{`Our government agency clients found themselves a go-to source of information during the pandemic. Members of
the public began to visit their websites for news, guidance and support in previously unprecedented numbers. `}</p>
    <p>{`Suddenly, we were witnessing never-before-seen extreme traffic levels. `}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20220720/requests.png",
        "alt": "A diagram showing elevated requests during the COVID-19 pandemic"
      }}></img></p>
    <p>{`The ability of these agencies to continue to deliver their vital services to people–without interruption–demonstrates
the level of scaling that Skpr can achieve. At no point were manual, reactionary scaling interventions required.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20220720/errors.png",
        "alt": "A diagram showing low error rates during the COVID-19 pandemic"
      }}></img></p>
    <p>{`Similarly, applications on our platform experienced an error rate of less than 0.01%. Skpr was well-prepared
and deliberately made to withstand a high level of fluctuating traffic.`}</p>
    <h2>{`Three ways to scale through a pandemic`}</h2>
    <p>{`It’s clear to see the spikes in 2021 were sustained, high-traffic events driven by the pandemic. But let’s dive a
little deeper into how Skpr maintained its performance.`}</p>
    <h3>{`1. A solid caching strategy`}</h3>
    <p>{`With AWS’ CDN Cloudfront included as standard on the edge, clients with high cache hit ratios were protected from
the majority of the traffic. Users could download 99% of pages and data faster without directly hitting the hosting
infrastructure. `}</p>
    <p>{`During the peak of the NSW outbreak, tens of thousands of people needed to check the lockdown rules that applied
to their postcode, all visiting one government webpage that needed to be served to them reliably without delays
or frustration.`}</p>
    <h3>{`2. Scaling out on demand`}</h3>
    <p>{`The remaining 1% of requests, such as form submissions, can’t be cached. Other hosting providers will be limited
in their capacity to handle this traffic. `}</p>
    <p>{`Skpr can detect when application utilisation is getting close to its maximum capacity. It will automatically scale
the number of application instances to ensure performance and reliability. When this occurs, it triggers monitoring
and alert notifications in real-time on the server.`}</p>
    <h3>{`3. A protected database`}</h3>
    <p>{`If the application makes too many connections to the database, it may struggle to keep up. This situation can
create a bottleneck that threatens to slow down the entire stack.`}</p>
    <p>{`Skpr uses a proxy layer between the application and the database. This layer pools connections, ensuring the database
isn’t overloaded by too many connections. `}</p>
    <p>{`Find out more about this in the post `}<a parentName="p" {...{
        "href": "/blog/architecting-skpr-mysql-service"
      }}>{`Architecting the Skpr MySQL Service`}</a></p>
    <h2>{`What hosting during the pandemic taught us`}</h2>
    <p>{`Hosting critical government websites during the pandemic showed that more effective caching strategies and optimisation
will reduce the amount of overall traffic hitting the server. The result is a faster experience for users and reduced
use of resources for the client.`}</p>
    <p>{`We also reaffirmed our commitment to supporting our clients’ teams. During worryingly unprecedented and unforeseen
circumstances, we reassured them that their websites could continue to be highly available and responsive.`}</p>
    <p>{`During this period, we saw how important it was for our clients to have confidence in our team. We were there to support
them 24/7 with fast responses informed by our deep knowledge of their sites and requirements. `}</p>
    <h2>{`Final thoughts`}</h2>
    <p>{`We’re proud of how well Skpr performed for our clients during the pandemic. `}</p>
    <p>{`Without a robust cloud hosting platform, their sites potentially faced the threat of unpredictable outages. These failures
would have had a significant knock-on effect for our clients and their users.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      